@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-msbox-sizing: border-box;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	margin: auto;
}

html {
	background: #1e293b;
}

html,
body,
#root {
	min-height: 100%;
	width: 100%;
}

video {
	width: 100% !important;
	height: 100vh !important;
	margin: 0 !important;
	object-fit: cover;
	position: absolute;
}

img {
	display: block;

	/* This rule is very important, please don't ignore this */
	max-width: 100%;
}

.a-loader-title {
	background: #1e293b !important;
	color: #1e293b !important;
}

.lock {
	overflow: hidden;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	position: fixed;
}

@supports (not (-webkit-touch-callout: none)) {
	/* Условия для всех браузеров, кроме Chrome */
	.margin-safe-bottom {
		margin-bottom: env(safe-area-inset-bottom);
	}
}

/* Для Chrome */
@supports (-webkit-touch-callout: none) {
	.margin-safe-bottom {
		margin-bottom: 0; /* Или другое значение, которое вы хотите использовать */
	}
}

.infinite-scroll-component {
	overflow: hidden !important;
}
