.editor-wrapper {
	background: #334155;
	border-radius: 0.5rem;
	padding: 1rem;
}

.editor-container {
	border-top: 1px solid #475569;
	margin-top: 1rem;
	padding-top: 1rem;
}

.toolbar-grid {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	flex-basis: 1fr 1fr;
}

button {
	margin-right: 8px;
	font-size: 1rem;
	padding: 10px;
	border: none;
	background: #fff;
	cursor: pointer;
}

.leftAlign {
	text-align: left;
}
.rightAlign {
	text-align: right;
}
.centerAlign {
	text-align: center;
}
.justifyAlign {
	text-align: justify;
}
